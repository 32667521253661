@use "sass:color";@use 'default' as *;.VariantComparisonHeader {
}

.flex-header {
  display: flex;
}

span.header-center {
  align-self: start;
  text-align: center;
}

.header-moveable {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-move-concept {
  cursor: pointer;
  font-size: 2.3em;
}

.yearly-aggregates-loader {
  margin-top: 20px;
}
