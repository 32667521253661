@use "sass:color";@use 'default' as *;.LocationOverviewTable {
  .ReactTable {
    border: none;
  }

  .HeaderWithCustomSorting .header-wrapper {
    justify-content: flex-start;
  }

  .HeaderWithCustomSorting {
    &.metering-data .header-wrapper {
      justify-content: center;
    }
  }

  .ReactTable .rt-tbody {
    overflow-x: hidden;
  }

  .ReactTable .rt-tbody > .rt-tr-group {
    margin-top: 2rem;
    padding: 1rem 1rem 0 1rem;
    border: 1px solid #21252940;
    border-radius: 10px;

    .rt-tr {
      margin-bottom: 1rem;
      background: none;
    }
  }

  .ReactTable .rt-tbody > .rt-tr-group > .rt-tr {
    margin-bottom: 0;
  }

  .sub-row {
    display: flex;
    align-items: center;
    min-height: 4.5rem;
    margin-top: calc(-1rem - 7px);
    padding: 0 5px;
  }

  .rt-td div .sub-row:not(:last-child) {
    border-bottom: 1px solid #21252940;
    margin-bottom: 2rem;
  }

  .ReactTable .rt-tbody .rt-tr .rt-td:nth-child(2) .sub-row {
    padding-left: 0;
  }

  .ReactTable .rt-tbody .rt-td {
    border-right: none;
  }

  .ReactTable .rt-tbody .rt-th,
  .ReactTable .rt-tbody .rt-td {
    padding: 7px 0 0 0;
  }

  .badge {
    margin-left: 0.5rem;
  }
}
