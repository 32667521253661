@use "sass:color";@use 'default' as *;.UpdateAnlageStromstgModal {
  .anlage-stromstg-edit-wizard-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    :not(:last-child) {
      margin-right: 0.25rem;
    }

    .custom-form-controls-inner {
      display: flex;
      flex-flow: row-reverse;
      justify-content: flex-start;

      :not(:first-child) {
        margin-left: unset !important;
        margin-right: 0.25rem;
      }

      :not(:last-child) {
        margin-right: unset !important;
      }
    }
  }
}
