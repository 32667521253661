@use "sass:color";@use 'default' as *;.AdvancePayments {
  .controls h5 {
    display: inline-block;
    margin-bottom: 0;
    margin-right: auto;
    align-self: flex-end;
  }

  .archived-row {
    margin-top: 30px;
  }
}
