@use "sass:color";@use 'default' as *;.ListItem {
  div {
    margin-right: 10px;
  }

  .sparklines {
    width: 100px;
  }

  a:hover,
  a:active {
    text-decoration: none;
  }

  .pinned {
    transform: rotate(-46deg);
  }

  .pinnable:hover {
    transform: initial;
  }

  .unpinned:hover {
    transform: rotate(-46deg);
  }
}
