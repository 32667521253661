@use "sass:color";@use 'default' as *;.sap-export-modal-date-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 50px;
}
.sap-export-modal-date-input {
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sap-export-modal-date-input-label {
  margin-right: 10px;
}

#month-field {
  width: 130px;
}
