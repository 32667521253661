@use "sass:color";@use 'default' as *;.CustomInputComponent {
  .custom-text-element-node-text-input {
    .mantine-Textarea-input {
      font-size: 1.2em;
      font-weight: 600;
      height: 80px;
    }
  }
  .cic-btn-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    .cancel-btn {
      cursor: pointer;
      margin-right: 10px;
      color: $danger-color;
    }
    .success-btn {
      cursor: pointer;
      color: $brand-color;
    }
  }
}
