@use "sass:color";@use 'default' as *;.NumberInput {
  input[type="number"] {
    /** Eliminate spinner buttons in Firefox */
    appearance: textfield;
    -moz-appearance: textfield;

    /** Eliminate spinner buttons in Chrome, etc. */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
