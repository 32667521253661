@use "sass:color";@use 'default' as *;.ComponentEditWizard {
}

.component-edit-wizard-buttons {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}

.EditForm {
  .tab-error-count {
    margin-left: 10px;

    i {
      margin-right: 0;
    }
  }

  .nav-link:not(.active) {
    .tab-error-count {
      color: red;
    }
  }

  .collapse-section-header {
    margin-bottom: 0;
    margin-left: 1rem;
  }

  .collapse-sub-section-header {
    font-weight: 400;
    margin-bottom: 0;
    margin-left: 1rem;
  }
  .section-header {
    margin-top: 30px;
  }

  .sub-section-header {
    font-weight: 400;
    margin-top: 20px;
  }
}

.collapse-header-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 1rem;
  flex-direction: row;
}

.arrow-container {
  display: flex;
  align-items: center;
}

.component-edit-wizard-header-container {
  display: flex;
  justify-content: space-between;
}
