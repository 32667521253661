@use "sass:color";@use 'default' as *;.DataWarningAlert {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-items: center;
  position: absolute;
  top: 70px;
  z-index: 100;
  .icon-alert-icon {
    align-self: flex-start;
  }

  .object-list {
    margin: 0;
  }
}
