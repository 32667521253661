@use "sass:color";@use 'default' as *;.ImportOrCreateStep {
  display: flex;
  flex-flow: column;

  min-height: 250px;

  &.mastr-sub-step {
    min-height: initial;
  }

  .choices {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    button {
      margin: 8px 0;
      width: 380px;
      height: 60px;
      white-space: normal;
    }
  }

  .mastr-substep {
    .explanation {
      font-style: italic;
    }
  }
}

.import-or-create-step-controls {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .back-button {
    margin-right: auto !important;
  }

  .continue-button {
    margin-left: 0.25rem;
  }
}
