@use "sass:color";@use 'default' as *;.metersDetails {
  margin-top: 1em;
}

.ReactTable {
  .rt-th {
    text-align: left;
    white-space: break-spaces;
  }
}
