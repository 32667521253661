@use "sass:color";@use 'default' as *;.MiniTile {
  margin-bottom: 10px;

  &.m-portlet {
    margin-right: 20px;

    &:last-child {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }

  .mini-tile-header {
    font-size: 1.5rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
    gap: 10px;

    .la-copy {
      color: #009692;
    }

    .Icon::before {
      vertical-align: middle;
    }
  }

  .mini-tile-body {
    display: flex;
    column-gap: 0.5rem;

    .item {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      gap: 20px;

      .label {
        margin-bottom: 5px;
      }

      .value {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 5px;
      }
    }
  }
}

#content-and-subheader .m-content .m-portlet:last-child {
  &.MiniTile.m-portlet:last-child {
    margin-bottom: 10px;
  }
}
