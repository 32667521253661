@use "sass:color";@use 'default' as *;.VariantComparison {
  .variant-comparison-tabs {
    margin-top: -15px;
    padding: 5px;
    background-color: white;
    font-size: 12px;

    .nav-link {
      cursor: pointer;

      &.active {
        background-color: $brand-color;
      }
    }
  }
}
