@use "sass:color";@use 'default' as *;.DueDateFields {
  margin-bottom: 10px;

  .grouped-fields {
    display: flex;
    align-items: center;
  }

  .group-prefix {
    margin-right: 10px;
  }

  .due-date-value,
  .due-date-unit {
    margin-bottom: 0;
  }

  .due-date-value input {
    width: 50px;
    height: 38px;
    margin-right: 10px;
  }

  &.is-invalid {
    .due-date-value input,
    .react-select__control {
      border-color: $danger-color;
    }
  }
}
