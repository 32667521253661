@use "sass:color";@use 'default' as *;.EnergyDataUploadFlow {
  display: flex;
  flex-flow: column;
  align-items: center;

  .ExplanationText {
    margin: 2em auto;
    margin-top: 0;
    width: 90%;

    h5 {
      margin-bottom: 1rem;
    }

    section {
      margin-bottom: 1rem;
    }

    .card {
      margin-top: 0.5rem;
    }

    .EnergyDataTemplateDownload {
      margin-bottom: 2rem;
    }
  }
}
