@use "sass:color";@use 'default' as *;.help-icon {
  color: $brand-color;
  align-self: center;
  -webkit-text-stroke: 0.5px;
  font-size: 1.5rem;

  &.help-icon-clickable {
    cursor: pointer;
  }
}

.icon-help-text-modal {
  .modal-body {
    max-height: 300px;
    overflow-y: auto;
  }

  .modal-footer {
    padding: 1rem;
    justify-content: center;
  }
}
