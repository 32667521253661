@use "sass:color";@use 'default' as *;.NoContracts {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;

  .empty-page-header {
    margin-bottom: 30px;
    font-weight: 600;
  }

  .empty-page-bodytext {
    margin-bottom: 30px;
    color: $custom-grey;
    font-weight: 600;
  }
}
