@use "sass:color";@use 'default' as *;div.optinode-tooltip {
  z-index: 1070;
  max-width: 600px; // for wide text like paragraph 9a (bestimmte Prozesse)
  border: 1px solid $custom-light-grey;
  padding: 7px 20px 7px 20px;
  // if you change this, make sure convert and update the tooltip shadow value in mantineTheme.ts
  box-shadow: 0 1px 15px 1px rgba(color.scale(#c5c2cc, $lightness: -50%), 0.1);
  font-size: 0.875rem;
  font-weight: 400;
  color: $status-rejected-color;

  .react-tooltip-arrow {
    border: 1px solid $custom-light-grey;
    border-top-color: #fff;
    border-left-color: #fff;
  }

  p {
    margin: 0;
  }
}
