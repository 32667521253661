@use "sass:color";@use 'default' as *;.DataGapsChart {
  .no-data-display {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    z-index: 1000;
    position: relative;
    top: 190px;
    font-weight: 600;
    .no-data-display-check-circle {
      margin-right: 10px;
      color: $success-color;
      font-size: 3rem;
    }
  }
}
