@use "sass:color";@use 'default' as *;.WizardTabBarComponent {
  .ErrorInTabName {
    margin-left: 10px;

    i {
      margin-right: 0;
    }
  }
}

.nav-link:not(.active) {
  .WizardTabBarComponent {
    .ErrorInTabName {
      color: red;
    }
  }
}
