@use "sass:color";@use 'default' as *;.StatusSymbol {
  display: inline-block;
  border: 1px #000 solid;
  border-radius: 50%;
  min-width: 16px;
  width: 16px;
  min-height: 16px;
  height: 16px;
  margin: 0 5px;
}
