@use "sass:color";@use 'default' as *;.SiteStatus {
  .tabs-and-more {
    display: flex;

    .tabs {
      flex-grow: 1;
    }
  }

  .ReactTable {
    .rt-resizer {
      width: 14px; // override react-table to make a smaller resize control
      right: -7px;
    }

    .rt-th {
      overflow: initial;

      .btn-group {
        width: 100%;
        height: 28px;

        .dropdown-toggle {
          width: 100%;
          padding: 0.35rem 16px 0.35rem 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .filter-dropdown-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &.DropdownFilter {
          &.show {
            .dropdown-toggle {
              &.dropdown-toggle-with-search {
                padding-left: 0;
              }
            }
          }
        }
      }

      .DateRangePickerInput__showClearDates {
        padding-right: 20px;

        .DateRangePickerInput_clearDates {
          margin-right: 0;
        }
      }
    }

    .rt-tr {
      align-items: center;
    }

    .rt-td {
      white-space: unset;
    }
  }

  .no-data-component p {
    margin: 10px;
    text-align: center;
  }

  .CustomersCell {
    list-style-position: inside;
    padding-left: 0;
  }
}

.site-status-loader {
  margin: 1rem;
}

.onboarding-todos-modal {
  .onboarding-todo-list {
    list-style-type: none;
    padding-left: 15px;
  }

  .onboarding-todo-list-item {
    display: flex;
    align-items: center;
    margin: 5px 0;
  }
}

.linked-label-cell-span {
  color: $primary-color;
  cursor: pointer;
}

.linked-label-cell-popover-body {
  font-size: 13px;

  .linked-label-cell-popover-body-link {
    margin-bottom: 3px;
  }
}
