@use "sass:color";@use 'default' as *;.Notes {
  .Notes-entry {
    margin-bottom: 25px;

    textarea {
      width: 100%;
      height: 100px;
    }
  }

  .Notes-list {
    .note {
      .info {
        min-height: 20px;
      }

      .text {
        margin-top: 2px;
      }
    }
  }

  .author,
  .spacer,
  .date {
    display: inline-block;
    font-size: small;
  }

  .author {
    font-weight: 500;
  }

  .spacer {
    margin: 0 10px;
  }

  .date {
    color: #888;
  }

  .controls {
    display: none;

    .edit-icon {
      margin-right: 3px;
    }
  }

  .Note {
    &:hover,
    &:focus,
    &:focus-within {
      .controls {
        display: initial;
      }
    }
  }
}
