@use "sass:color";@use 'default' as *;.ExpressionEditor {
  align-items: stretch !important; // needed due to different import order on build

  .rte-root {
    position: static;
    height: 100%;

    &.is-invalid {
      border-color: $danger-color;
      border-width: 1px;
    }

    .mantine-RichTextEditor-typographyStylesProvider,
    .mantine-RichTextEditor-content {
      height: 100%;
    }
  }

  .tiptap {
    height: 100%;
    line-height: 2;
  }

  .expression-variable {
    background: $brand-color;
    margin: 0 2px;
    border-radius: 12px;
    padding: 2px 8px;
    color: white;
    font-weight: 500;
    white-space: nowrap !important;
  }

  .expression-value-display {
    line-height: 2;
  }

  .expression-inputwrapper {
    position: relative;
  }

  .expression-clear {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
