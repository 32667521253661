@use "sass:color";@use 'default' as *;.AnlageStromstgFlow {
  .header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }

  .edit-mode-alert {
    margin-top: 2rem;
  }

  .submit-button {
    display: block;
    margin-left: auto;
  }
}
