@use "sass:color";@use 'default' as *;.FeedinPage {
  .controls {
    display: flex;
    flex-flow: row;
    align-items: flex-end;
    gap: 24px;

    &.loading {
      justify-content: center;
    }

    label {
      font-weight: 500;
    }

    .contract-dropdown {
      width: 500px;
      margin-bottom: 0;
    }
  }

  .IconAlert {
    margin-top: 32px;
  }
}
