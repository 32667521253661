@use "sass:color";@use 'default' as *;.MantineLineChart {
  user-select: none;
  .zoom-out-btn {
    font-size: 10px;
    padding: 0.4rem 0.5rem;
  }

  &.ChartTooltip {
    .dot {
      border-radius: 100px;
      height: 10px;
      width: 10px;
    }
  }

  .ChartLegend {
    overflow-x: hidden;
    overflow-y: auto;

    .legend-item {
      display: flex;
      align-items: center;

      .legend-label {
        text-align: start;
        text-wrap: auto;
      }

      &.inactive {
        .legend-label {
          color: $custom-light-grey;
        }
      }
    }
  }
}
