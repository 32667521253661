@use "sass:color";@use 'default' as *;.ErrorFallbackContainer {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;

  h2,
  p {
    max-width: 400px;
  }

  h2 {
    margin-bottom: 1em;
  }

  p {
    margin-bottom: 1.5em;
  }
}
