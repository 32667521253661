@use "sass:color";@use 'default' as *;.Breadcrumbs {
  .MenuItem {
    span {
      /* 150px = side menu, 72px = profile */
      max-width: calc((100vw - 150px - 72px) / 4);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media screen and (min-width: 1201px) {
        /* 150px = side menu, 124px = profile, 75px = fudge */
        max-width: calc((100vw - 150px - 124px) / 4 - 75px);
      }
    }

    &.menu-item-variant-sub-menu {
      padding-left: 0;

      i {
        font-size: 10px;
        cursor: pointer;
      }
    }

    &.m-menu__item {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .PageTitle {
    display: block;
    font-size: 1.07rem;
    font-weight: 500;
  }
}
