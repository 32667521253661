@use "sass:color";@use 'default' as *;.premium-badge-container {
  border: 1px solid #ef895b;
  color: #ef895b;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.premium-badge-text {
  font-weight: 500;
  margin-left: 5px;
}
