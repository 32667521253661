@use "sass:color";@use 'default' as *;.RawEnergyDataPage {
  .date-range-input {
    display: flex;
    align-items: center;
    gap: var(--mantine-spacing-sm);

    .mantine-InputWrapper-label {
      margin: 0;
      font-weight: bold;
    }
  }

  .mantine-Accordion-item {
    background: white;
    border: 0;
    border-radius: 0;
    box-shadow: var(--mantine-shadow-sm);
  }

  .mantine-Accordion-control {
    height: 5rem;
  }

  .mantine-Accordion-chevron {
    margin-inline-start: var(--mantine-spacing-md);
    margin-inline-end: var(--mantine-spacing-xs);
    color: darkgray;

    &[data-rotate] {
      transform: rotate(90deg);
    }
  }

  .mantine-Accordion-content {
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-lg);
  }
}
