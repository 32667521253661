@use "sass:color";@use 'default' as *;.FilterDropdown {
  .filters-count {
    margin-left: 4px;
    color: $brand-color;
  }

  .dropdown-menu {
    max-height: calc(100vh - 200px);
    min-width: 300px;
    overflow-y: auto;
  }

  .m-checkbox:last-child {
    margin-bottom: 0;
  }

  .footer {
    display: flex;
    justify-content: space-between;
  }
}
