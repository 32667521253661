@use "sass:color";@use 'default' as *;.DeliveryConstraint {
  input.form-check-input {
    width: 20px;
    height: 20px;
  }
  .m-switch input:checked ~ span::before {
    box-shadow: none;
  }
  .m-switch input:checked ~ span::after {
    box-shadow: none;
  }
}
