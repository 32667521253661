@use "sass:color";@use 'default' as *;@use "../../../global" as *;

.project-row {
  font-size: larger;
  cursor: pointer;
  border-spacing: 2px;
  border-top: 1px solid #f4f5f8;
  padding: 0.75rem;
  vertical-align: top;

  @include shadow-hover;

  .project-name-link {
    color: $node-energy-black;

    &:hover {
      text-decoration: none;
    }
  }

  .icons {
    display: inline-flex;
    justify-content: flex-end;

    .la {
      padding: 0 0.1rem;
    }
  }
}
