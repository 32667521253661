@use "sass:color";@use 'default' as *;.MoreVariantOptions {
  .m-section__heading {
    font-weight: bold;
  }

  .options {
    .option {
      display: flex;
      flex-flow: column;

      .input-and-unit {
        display: flex;
        flex-flow: row;
        align-items: center;

        .FormField {
          width: 100%;
          margin: 0;

          input {
            text-align: right;
          }

          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
    }
  }
}
