@use "sass:color";@use 'default' as *;.TodoTable {
  .mantine-Paper-root {
    .mantine-Flex-root {
      padding: 0;
      align-items: center;
    }

    .mrt-table-head-cell-content-wrapper {
      width: min-content;
    }
  }

  .mantine-Table-td {
    .overdue {
      color: $warning-color;
    }

    .company-icon {
      height: 1.1rem;
    }

    .priority-high input {
      background-color: rgba(231, 60, 48, 0.4);
    }

    .priority-medium input {
      background-color: #fdeeca;
    }

    .priority-low input {
      background-color: #c3e5ed;
    }

    .priority-unknown input {
      background-color: rgba(225, 234, 237, 0.8);
    }
  }
}
