@use "sass:color";@use 'default' as *;.SearchBarWrapper {
  display: flex;

  .search-bar-input-field {
    margin-bottom: 0;

    .form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding-right: 2.3rem;
    }
  }

  .search-bar-input {
    position: relative;
    left: 1px;

    .search-bar-input-field {
      width: 470px;
    }

    .search-bar-clear-btn {
      outline: unset;
      border: unset;
      background-color: transparent;

      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);

      display: flex;
      justify-content: center;
      align-items: center;

      width: 18px;
      height: 18px;
      font-size: 1.3rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 92px;
    height: 33.5px;
  }
}
