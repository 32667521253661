@use "sass:color";@use 'default' as *;.StructureViewButtons {
  margin-bottom: 2.2rem;
  padding-left: 10px;
  padding-right: 10px;

  .buttons-row-two-buttons {
    justify-content: center;
  }

  .button-col {
    padding: 5px;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
