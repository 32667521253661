@use "sass:color";@use 'default' as *;.CustomTextElementNode {
  .custom-text-element-body {
    font-size: 2.5em;
    font-weight: 600;
    .custom-text {
      margin: 0;
      line-height: normal;
    }
  }
}
