@use "sass:color";@use 'default' as *;.DirektvermarktungView {
  .contracts-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .contracts-body {
    margin-top: 30px;
  }

  .contracts-no-result-icon {
    width: 40px;
  }
}
