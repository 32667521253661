@use "sass:color";@use 'default' as *;.CounterDataView {
  button:not(:first-child) {
    margin-left: 10px;
  }

  .jump-controls {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .jump-text-date {
      display: flex;
      align-items: center;
      justify-content: center;

      .jump-text {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 3px 0 0;

        .search-icon {
          font-size: 2.2rem;
        }
      }

      .DatePicker {
        width: 300px;
      }
    }

    .alert {
      margin-top: 10px;
    }
  }

  .DataSheet {
    margin-top: 0;
  }
}
