@use "sass:color";@use 'default' as *;.DataSheet {
  display: block;
  padding: 5px;
  margin: 20px auto auto;

  span.data-grid-container {
    display: block;
    overflow: auto;
    max-height: 70vh;
  }

  table.data-grid {
    margin: auto;
  }

  input {
    //need to override defaults from react-datasheet
    width: 100% !important;
    height: 18px !important;
    font-size: 11px;
  }

  table.data-grid {
    background: white;

    tr {
      td.cell:not(.selected) {
        border: 1px solid #ececec;
      }
      td.cell,
      th.cell {
        width: 120px;
        padding: 2px 4px;
        font-size: 12px;
      }
      td.cell:first-child,
      td.cell:nth-child(2) {
        white-space: nowrap;
      }
    }

    tr:first-child td.cell {
      padding: 0 5px;
    }
  }
  td {
    color: #2b2c31 !important;
  }

  td[mark="changed"] {
    background-color: #cff1f1 !important;
  }

  .counter-data-pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin: 5px 20px;
      width: 75px;
    }
  }
}
