@use "sass:color";@use 'default' as *;$colors: (
  light-grey: #f9f9f9
);

@each $colorname, $color in $colors {
  .background-color--#{$colorname} {
    background-color: $color;
  }
}

.metering-concept-row:hover div.col {
  background-color: inherit;
}
