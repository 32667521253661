@use "sass:color";@use 'default' as *;.grey-out {
  opacity: 0.5;
}

.clickable {
  cursor: pointer;
  &:hover {
    background-color: $custom-very-light-grey;
  }
}

.label-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.break-word {
  word-break: break-word;
}

.details-head {
  font-weight: bolder;
}

.details-body {
  td {
    padding: 0 5px;
  }
}

.regulatory-duty-icon {
  min-width: 18px;
  min-height: 18px;
  border: 1px solid #505159;
  border-radius: 50px;
  color: #505159;
  font-size: 11px;
  font-weight: bolder;
  user-select: none;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
}
