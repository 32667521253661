@use "sass:color";@use 'default' as *;.Paragraph6CreditErrorMessage {
  font-weight: 400;

  .credit-error-description-line {
    margin-bottom: 0px;
  }
  .credit-error-header-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    .credit-error-header {
      margin-left: 10px;
      font-weight: 600;
    }
    .credit-error-icon {
      margin-bottom: 5px;
    }
  }
  .credit-error-info-container {
    margin-top: 10px;
  }

  .mantine-Accordion-control:hover {
    background-color: transparent;
  }

  .mantine-Accordion-item[data-active="true"] {
    background-color: transparent;
  }
}
