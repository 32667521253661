@use "sass:color";@use 'default' as *;.InputWrapper {
  .invalid-feedback {
    display: block;
    color: $danger-color;
  }

  .warning {
    border-color: $warning-color;
  }

  .warning-feedback {
    display: block;
    font-size: 80%;
    margin-top: 0.25rem;
    color: $warning-color;
  }

  &.input-group .input-group-append > .input-group-text {
    &.input-group-error-icon {
      padding: 0.3rem 0.5rem;
      background-color: white;

      .error-icon {
        color: $danger-color;
        font-size: 22px;
      }
    }
  }
}
