@use "sass:color";@use 'default' as *;@use "../RetryModal.scss" as *;

.parameter-set-modal {
  width: 90%;
  max-width: 1680px;

  .parameter-set-control {
    width: 30%;
    min-width: 150px;
  }
}
