@use "global-fonts" as *;

@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src:
    url("./fonts/opti-node/montserrat-v25-latin-200.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/montserrat-v25-latin-200.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-200italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  src:
    url("./fonts/opti-node/montserrat-v25-latin-200italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/montserrat-v25-latin-200italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src:
    url("./fonts/opti-node/montserrat-v25-latin-300.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/montserrat-v25-latin-300.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src:
    url("./fonts/opti-node/montserrat-v25-latin-300italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/montserrat-v25-latin-300italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src:
    url("./fonts/opti-node/montserrat-v25-latin-regular.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/montserrat-v25-latin-regular.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src:
    url("./fonts/opti-node/montserrat-v25-latin-italic.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/montserrat-v25-latin-italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src:
    url("./fonts/opti-node/montserrat-v25-latin-500.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/montserrat-v25-latin-500.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  src:
    url("./fonts/opti-node/montserrat-v25-latin-500italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/montserrat-v25-latin-500italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src:
    url("./fonts/opti-node/montserrat-v25-latin-600.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/montserrat-v25-latin-600.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  src:
    url("./fonts/opti-node/montserrat-v25-latin-600italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/montserrat-v25-latin-600italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src:
    url("./fonts/opti-node/montserrat-v25-latin-700.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/montserrat-v25-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src:
    url("./fonts/opti-node/montserrat-v25-latin-700italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/montserrat-v25-latin-700italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src:
    url("./fonts/opti-node/roboto-v30-latin-300.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/roboto-v30-latin-300.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src:
    url("./fonts/opti-node/roboto-v30-latin-300italic.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/roboto-v30-latin-300italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src:
    url("./fonts/opti-node/roboto-v30-latin-regular.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/roboto-v30-latin-regular.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src:
    url("./fonts/opti-node/roboto-v30-latin-italic.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/roboto-v30-latin-italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src:
    url("./fonts/opti-node/roboto-v30-latin-500.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/roboto-v30-latin-500.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src:
    url("./fonts/opti-node/roboto-v30-latin-500italic.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/roboto-v30-latin-500italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src:
    url("./fonts/opti-node/roboto-v30-latin-700.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/opti-node/roboto-v30-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
