@use "sass:color";@use 'default' as *;.DirektvermarktungContractTitle {
  display: flex;
  align-items: center;
  user-select: none;

  .contract-name {
    flex-grow: 1;
  }

  .generator-count {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 0 2rem 0 1rem;
    font-size: 1rem;
  }

  .action-panel {
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .edit-icon {
    display: flex;
  }
}
