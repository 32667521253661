@use "sass:color";@use 'default' as *;.CreateComponentDropdown {
  text-align: right;
  z-index: 1;
}

.CreateComponentDropdown--disabled {
  opacity: 0.5;
  background-color: $custom-light-grey !important;
  border: none;
  color: black;
}
