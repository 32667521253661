@use "sass:color";@use 'default' as *;.EnergyDataAcquisitionSelectionModal {
  h4 {
    margin-bottom: 15px;
  }

  .scrollable-dropdown {
    overflow-y: auto;
    max-height: 50vh;

    .dropdown-header {
      padding: 0.5rem 0.8rem;
      font-size: 1rem;
    }
  }

  .defined-filter-dropdown {
    margin-bottom: 20px;

    select {
      width: 400px;
    }
  }

  .filters {
    margin-bottom: 20px;

    .filter-list {
      border: 1px solid #d3d3d373;
      border-radius: 10px;
      margin: 10px 10px 20px 10px;
      padding: 20px 10px 10px 10px;

      .add-condition-button {
        margin-left: 5px;
        margin-right: 10px;
      }
    }

    .filter {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 5px;

      .filter-type {
        align-self: flex-start;
        margin-bottom: 10px;
      }

      .connector-text {
        display: inline-block;
        margin: 0 10px 10px 10px;
      }

      .filter-options {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .filter-option {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .FormField {
            margin: 0;
          }
        }

        .option-buttons {
          display: flex;
          margin-bottom: 10px;
          margin-left: 5px;

          button {
            padding: 3px 2px 2px 2px;
          }
        }
      }

      .remove-filter-button {
        margin-left: auto;
        cursor: pointer;

        &.remove-filter-button {
          font-size: 150%;
        }
      }
    }

    .filter-controls {
      display: flex;

      button {
        margin-right: 10px;
      }
    }
  }

  .acquisition-list {
    padding: 0 5px;

    .selection-buttons {
      display: flex;
      margin-bottom: 10px;

      button {
        margin-right: 5px;
      }
    }

    .no-acquisitions {
      text-align: center;
    }
  }
}

.DefinedFilterNamingModal {
  .modal-body {
    display: flex;
    align-items: center;

    .FormField {
      flex-grow: 1;
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
}
