@use "sass:color";@use 'default' as *;.MetersSaveWarningModalHeader {
  h5 {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .MeterSaveWarningIcon {
    width: 16px;
  }

  .MeterSaveWarningTitle {
    font-weight: bold;
    font-size: 1.3rem;
  }
}

.MeterSaveWarningDescription {
  p {
    margin-bottom: 0;
    font-weight: 400;
  }
}
