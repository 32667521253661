@use "sass:color";@use 'default' as *;.LayoutContainer {
  height: 100vh;
  display: flex;

  .LayoutGradient {
    flex: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom left, #11ccbb, #1cb6e4);

    .LogoWhite {
      position: absolute;
      left: 24px;
      top: 24px;
    }

    .LayoutHeadingText {
      color: #ffffff;
      font-size: 5rem;
      font-weight: 600;
      line-height: 7rem;
    }
  }

  .LayoutContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    height: 100%;
    padding-top: 2em;
    padding-bottom: 1em;
    background-color: #ffffff;

    .OptiNodeLogo {
      margin: 0 auto;
      max-width: 200px;
    }

    .LayoutFooterProductLogo {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 1em;
      margin-bottom: 1em;

      .ProductByText {
        font-size: 0.85rem;
      }
    }
  }
}

@media screen and (max-width: 993px) {
  .LayoutContainer {
    .LayoutGradient {
      display: none;
    }
  }
}
