@use "sass:color";@use 'default' as *;.PvPlantsFields {
  .single-plant {
    .FormField2 {
      flex-grow: 1;
    }

    .Icon {
      color: $danger-color;
      margin-bottom: 19px;
    }

    .Icon.error {
      margin-bottom: 39px;
    }
  }

  .form-item-info-text-container {
    margin-top: 0px;
  }

  .icon-link-container {
    margin-top: 16px;
    margin-bottom: 30px;
    color: $brand-color;
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;

    .icon-link-text {
      margin: 0;
      margin-left: 5px;
    }
  }
}
