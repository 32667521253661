@use "sass:color";@use 'default' as *;.todo-status-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px;
  font-weight: 500;
  align-items: center;
}

.todo-status-title {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.todo-status-title.staff {
  width: 60%;
}

.todo-status-icon {
  color: $brand-color;
  margin-right: 4px;
}

.optinode-assignment-todo-list-container {
  margin-top: 15px;
}
