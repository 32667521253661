@use "sass:color";@use 'default' as *;.general-import-details-list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    margin: 5px 0;
    word-break: break-all;
  }
}
