@use "sass:color";@use 'default' as *;.vbl-control-and-help-container {
  .vbl-help-text-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 10px;
    align-items: center;
  }
  .vbl-controls-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
