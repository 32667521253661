@use "sass:color";@use 'default' as *;.MachineAccountPage {
  .control-panel {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
  }

  .HeaderWithCustomSorting .header-wrapper {
    justify-content: flex-start;
  }

  .email {
    max-width: 100%;

    a {
      max-width: calc(100% - 25px);
    }

    &-disabled {
      cursor: not-allowed;

      a {
        pointer-events: none;
        color: $custom-grey;
      }
    }
  }

  .reimport-icon {
    transform: rotate(90deg);
    cursor: pointer;

    &-spinner {
      transform: none;
      cursor: initial;
    }
  }
}
