@use "sass:color";@use 'default' as *;.NonSelectableDropdownItem {
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-menu > .NonSelectableDropdownItem {
  padding: 0.7rem 1.2rem;
}
