@use "sass:color";@use 'default' as *;/*
    These styles are copied over from the latest version of reactstrap (9.2.0).
    This is unfortunately necessary because the version of bootstrap in 
    styles.bundle.css old and conflicts with the style and functionality of the
    latest version of Badge from reactstrap.
 */
.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.bg-brand {
  background-color: $brand-color !important;
}

.bg-secondary {
  background-color: rgb(108, 117, 125) !important;
}
