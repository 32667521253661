@use "sass:color";@use 'default' as *;.DateInput {
  .mantine-DateInput-input {
    font-family: sans-serif, Arial;
    font-size: 1rem;
    font-weight: initial;
    line-height: 1.25;
    color: $node-energy-black;
  }

  .datepicker-calendar {
    font-size: 1.8rem;
  }
}
