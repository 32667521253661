@use "sass:color";@use 'default' as *;.EdaDetailModal {
  .overview {
    .eda-property {
      margin-bottom: 10px;
      word-break: break-all;
    }

    .eda-property-value {
      margin-left: 10px;
    }

    .tag {
      display: inline-block;
      margin: 0.125em 0.5em 0.125em 0;
      padding: 0.125rem 0.375rem;
      font-size: 0.85em;
      font-weight: bold;
      border-radius: 2px;
      white-space: nowrap;
    }
  }
}
