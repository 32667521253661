@use "sass:color";@use 'default' as *;.Invoices {
  .invoice-file-icon {
    width: 20px;
    margin-right: 10px;
  }
  .controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;

    .create-button {
      margin-right: 3px;
    }
    .preview-button {
      margin-right: 3px;
      color: $brand-color;
      font-weight: 500;
    }
    .InvoiceActionsDropdown {
      margin-right: 10px;
      .invoice-action-dropdown-toggle {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 130px;
      }
      .archive-invoices-dropdown-item {
        color: $danger-color;
        &.disabled {
          color: $custom-grey;
        }
      }
    }
  }

  .rt-td,
  .no-data-component {
    display: flex;
    align-items: center;
  }

  .no-data-component {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    p {
      margin: 0;
    }
  }

  .centered {
    text-align: center;
    width: 100%;
  }
}
