@use "sass:color";@use 'default' as *;.status-cell {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

  .status-text {
    margin-left: 4px;
  }
}
