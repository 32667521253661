@use "sass:color";@use 'default' as *;.ConfigurationConfirmationModal {
  .modal-content .modal-header .modal-title {
    font-weight: 700;
    font-size: 1.2em;
  }

  .generator-edit-modal-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .configuration-modal-generator-list-header {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
