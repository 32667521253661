@use "sass:color";@use 'default' as *;.CustomContainerElementPopup {
  position: absolute;
  top: 200px;
  left: 200px;
  z-index: 1;
  max-width: 280px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  padding: 9px 14px;
  text-align: left;
  word-wrap: break-word;
  line-height: 20px;

  .LinksAndDetails {
    display: flex;
    flex-direction: column;
  }

  .small {
    font-size: 80%;
    font-weight: 400;
  }

  .delete-disabled {
    color: $custom-light-grey;
  }

  p {
    margin: 0;
  }
}
