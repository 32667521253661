@use "sass:color";@use 'default' as *;.EnergyDataControls {
  .FormField {
    margin: 0;
  }

  .date-range-start,
  .date-range-end {
    width: 120px;
  }

  .control-dropdown {
    display: flex;
    align-items: center;
    gap: var(--mantine-spacing-sm);

    .mantine-Select-label {
      margin: 0;
      font-weight: bold;
    }

    &.time-intervals .mantine-Select-input {
      width: 140px;
    }

    &.units .mantine-Select-input {
      width: 220px;
    }
  }
}
