@use "sass:color";@use 'default' as *;.TodosV2 {
  .TodosV2-portlet {
    .m-portlet__head-title {
      width: 100%;
    }

    button {
      height: fit-content;
    }
  }

  .tabs {
    .tabs-list {
      button:focus {
        outline: none;
      }

      .OptiSupportHelpLink {
        position: absolute;
        right: 0px;
      }
    }
  }
}
