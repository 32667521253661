@use "sass:color";@use 'default' as *;.ConsumptionShareMaskContainer {
  #consumption-share-date-range-picker {
    width: 100%;
    margin-bottom: 40px;
    .DateRangePicker {
      width: 100%;
      .DateRangePickerInput {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .DateInput {
          border: 1px solid $custom-light-grey;
          width: 45%;
          display: flex;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }
  .consumption-share-mask-header {
    margin-bottom: 30px;
  }
  .consumption-share-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .consumption-share-consumer {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: space-between;
    }
    .consumption-share-percentage {
      width: 45%;
    }
  }
  .consumption-share-mask-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
