@use "sass:color";@use 'default' as *;.InfoIcon {
  display: flex;
  align-items: center;

  .info-icon {
    font-size: 25px;
    margin-right: 5px;
  }

  .instruction-icon {
    color: $brand-color;
  }

  .information-icon {
    color: $custom-grey;
  }

  .warning-icon {
    color: $warning-color;
  }
}
