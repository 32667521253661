@use "sass:color";@use 'default' as *;.helper-text-wrapper {
  .helper-text,
  .invalid-feedback,
  .warning-feedback {
    display: block;
    width: 100%;
    font-size: 80%;
    margin-top: 0.25rem;
  }

  .invalid-feedback,
  .warning-feedback {
    font-weight: bold;
  }

  .invalid-feedback {
    color: $danger-color;
  }

  .warning-feedback {
    color: $warning-color;
  }
}
