@use "sass:color";@use 'default' as *;.TodosProgress {
  border: 1px solid $custom-light-grey;
  height: 200px;
  width: 400px;
  overflow: visible;

  .mantine-DonutChart-root {
    flex-grow: 1;
    width: auto;
  }

  .label {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .donut-start {
    position: absolute;
    top: 87%;
    left: calc(50% - 100px);
    transform: translate(-50%, -50%);
  }

  .donut-end {
    position: absolute;
    top: 87%;
    left: calc(50% + 100px);
    transform: translate(-50%, -50%);
  }
}
