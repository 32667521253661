@use "sass:color";@use 'default' as *;.uploaded-template {
  display: flex;
  justify-content: space-between;

  .template-name {
    display: flex;
    align-items: center;

    .template-icon {
      font-size: 20px;
      color: $primary-color;
    }
  }

  .remove-template-icon {
    cursor: pointer;
    color: $danger-color;
    font-size: 20px;
  }
}
