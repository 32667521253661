@use "sass:color";@use 'default' as *;.StructureViewDiagramContainer {
  display: flex;
  flex-flow: column;
  height: 100%;

  &.analyzer {
    min-height: 600px;
  }

  &.manager {
    min-height: 680px;
  }

  .portlet-body {
    display: flex;
    flex-flow: column;
    flex: 1;

    .structure-view-diagram-controls {
      height: 36px;
    }
  }

  #graph {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-direction: column;
    width: 100%;
  }

  .with-premium-badge {
    display: flex;
    justify-content: space-between;
  }

  .structure-view-diagram-top-row {
    z-index: 3;
  }
}

.structure-view-diagram-container-storybook-wrapper {
  .StructureViewDiagramContainer {
    height: 550px;
  }
}
